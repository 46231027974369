import './populars.scss'
import PopularItem from './item'
import { useGetProductsQuery } from '../../../../store/services/api'

function Populars() {
	
	const { data: products, isLoading } = useGetProductsQuery(6)

	if (!isLoading) {
		return (
			<div className="popular-wrapper">
				<div className="wrapper-title">
					<h1>Son Eklenenler</h1>
				</div>
				<div className="popular-posts">
					{products?.results?.map((blog) => (
						<PopularItem key={blog.id} blog={blog} />
					))}
				</div>
			</div>
		)
	}
}

export default Populars
