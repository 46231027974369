import Corporate from "./pages/corporate";
import Home from "./pages/home";
import MainLayout from "./pages/layouts/MainLayout";
import Content from './components/content'
import Services from './pages/services'
import About from "./pages/about";
import Contact from "./pages/contact";
import BlogsPage from "./pages/blogs";
import Blog from "./pages/blog";

export const routes = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: 'hakkimizda',
                element: <About />
            },
            {
                path: 'kurumsal',
                children: [
                    {
                        index: true,
                        element: <Corporate />
                    },
                    {
                        path: ':slug',
                        element: <Content />
                    }
                ]
            },
            {
                path: 'iletisim',
                element: <Contact />
            },
            {
                path: 'hizmetlerimiz',
                children: [
                    {
                        index: true,
                        element: <Services />
                    },
                    {
                        path: ':slug',
                        element: <Content />
                    }
                ]
            },
            {
                path: 'urunler',
                children: [
                    {
                        index: true,
                        element: <BlogsPage />
                    },
                    {
                        path: ':slug',
                        element: <Blog />
                    }
                ]
            }
        ]
    }
]