import { Form, Formik } from 'formik'
import TextInput from '../../forms/input'
import RichTextInput from '../../forms/textarea'
import './contact.scss'
import toast from 'react-hot-toast'
import { formSchema } from './formSchema'
import { useSendMessageMutation } from '../../store/services/api'

function Contact() {
	const [sendMessage, result] = useSendMessageMutation()

	const submitHandle = async (values, { resetForm }) => {
		sendMessage(values)

		if (result) {
			toast.success('Mesajınız başarıyla gönderildi!')
		} else {
			toast.error('Mesaj gönderilirken hata oluştu!')
		}

		resetForm()

	}

	return (
		<section id="contact">
			<div className="container">
				<h2>İletişim</h2>
				<h1>Daha İyi Çözümler İçin</h1>
				<div className="contact-form">
					<Formik
						initialValues={{
							name: '',
							email: '',
							phone: '',
							subject: '',
							message: '',
						}}
						onSubmit={submitHandle}
						validationSchema={formSchema}
					>
						{(props) => (
							<Form>
								<div className="input-group">
									<div className="form-element">
										<TextInput name="name" placeholder="İsminiz" />
									</div>
									<div className="form-element">
										<TextInput name="email" placeholder="E-posta Adresi" />
									</div>
									<div className="form-element">
										<TextInput name="phone" placeholder="Telefon Numarası" />
									</div>
									<div className="form-element">
										<TextInput name="subject" placeholder="Konu" />
									</div>
									<div className="form-element element-full">
										<RichTextInput name="message" placeholder="Mesajınız" />
									</div>
									<div className="form-element element-full">
										<button type="submit" className="contact-button">
											Gönder
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</section>
	)
}

export default Contact
