import './solutions.scss'
import { Link } from 'react-router-dom'
import { useGetContentsQuery } from '../../store/services/api'
import Loader from '../loader'

function Solutions({ name, title, subtitle }) {

	const { data: contents = [], isLoading } = useGetContentsQuery()

	const items = contents.filter(
		(item) => item.navbar.slug.replace('/', '') === name
	)

	if (isLoading) return <Loader />

	return (
		<section id="solutions">
			<div className="container">
				<h2>{title}</h2>
				<h1>{subtitle}</h1>
				<div className="solutions">
					{items.map((item) => (
						<div key={item.id} className="solution">
							<div className="solution-icon">
								<i className={item.icon}></i>
							</div>
							<div className="solution-name">
								<h1>{item.title}</h1>
							</div>
							<div className="solution-desc">
								<span>
									{item.desc.slice(0, 100)}
									{item.desc.length > 0 ? '...' : null}
								</span>
							</div>
							<Link to={'/' + item.navbar.slug + '/' + item.slug}>
								Daha Fazla
							</Link>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}
export default Solutions
