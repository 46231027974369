import { Link } from "react-router-dom"
import { useGetConfigsQuery } from "../../../store/services/api"
import './item.scss'

function ContactItem() {

	const { data:config } = useGetConfigsQuery()

	return (
		<article className="contact-item">
			<div className="item-title">
				<h1>{config?.province} / {config?.district}</h1>
			</div>
			<div className="item-desc">
				<p>
					{config?.address}
				</p>
			</div>
			<div className="item-links">
				<ul>
					<li>
						<Link to={config?.telephone}>
							{config?.telephone}
						</Link>
					</li>
                    <li>
                        <Link to={config?.email}>
							{config?.email}
						</Link>
                    </li>
				</ul>
			</div>
		</article>
	)
}

export default ContactItem
