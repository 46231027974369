import HeroBackground from '../../components/hero'
import Blogs from '../../components/blogs'
import Seo from '../../components/meta'

function BlogsPage() {
	return (
		<section>
			<Seo title="Replik Yazılım | Ürünler" />
			<HeroBackground
				title="Ürünler"
				breadcrumb={['Anasayfa', 'Ürünler']}
			/>
			<Blogs pagination={true} limit={6} />
		</section>
	)
}

export default BlogsPage
