import Blog from './components/Blog'
import './blogs.scss'
import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useGetProductsQuery } from '../../store/services/api'

function Blogs({ limit, pagination }) {
	const [page, setPage] = useState(0)
	
	const {data: products = []} = useGetProductsQuery({
		limit,
		offset: limit * page
	})

	return (
		<section id="blogs">
			<div className="container">
				<h2>Ürünler</h2>
				<h1>Tüm Ürünlerimiz</h1>
				<div className="blogs">
					{products?.results?.map((blog) => (
						<Blog key={blog.id} blog={blog} />
					))}
				</div>
				{products?.results?.length > 0 && (
					<React.Fragment>
						{pagination && (
							<ReactPaginate
								containerClassName="pagination-items"
								pageLinkClassName="pagination-item"
								activeLinkClassName="active-page"
								previousClassName="pagination-item"
								nextClassName="pagination-item"
								onPageChange={({ selected }) => setPage(selected)}
								pageCount={Math.ceil(products.count / limit)}
								previousLabel="Geri"
								nextLabel="İleri"
							/>
						)}
					</React.Fragment>
				)}
			</div>
		</section>
	)
}

export default Blogs
