import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Navigate, useParams } from 'react-router-dom'
import { resize } from '../../helper'
import HeroBackground from '../hero'
import './content.scss'
import Seo from '../meta'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useGetContentQuery } from '../../store/services/api'
import './markdown.css'
import Loader from '../loader'

function Content() {
	const { slug } = useParams()

	const { data: content, isLoading, error } = useGetContentQuery(slug)

	if (error) return <Navigate to="/404" />

	if (isLoading) return <Loader />

	return (
		<section id="content-page">
			<Seo
				title={'Replik Yazılım | ' + content.title}
				image={`/static/img/${content.image}`}
				description={content.desc}
			/>
			<HeroBackground
				title={content.title}
				breadcrumb={['Home', 'Kurumsal', content.title]}
			/>
			<section id="content">
				<div className="container">
					<h2>{content.navbar.title}</h2>
					<h1>{content.title}</h1>
					<hr />
					<div className="content-details">
						{content.image !== '' && (
							<div className="content-image">
								<LazyLoadImage src={resize(content.image, 1200, 500)} />
							</div>
						)}
						<ReactMarkdown
							children={content.content_markdown}
							remarkPlugins={[remarkGfm]}
							className="markdown-body"
						/>
					</div>
				</div>
			</section>
		</section>
	)
}

export default Content
