import { Outlet } from 'react-router-dom'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import { useGetMenusQuery, useGetConfigsQuery } from '../../store/services/api'
import Loader from '../../components/loader'
import { useEffect } from 'react'

function MainLayout() {
	const { data: menus = [], isLoading: loadingNav } = useGetMenusQuery()
	const { data: config, isLoading: loadingConfig } = useGetConfigsQuery()

	if (loadingConfig || loadingNav) return <Loader />

	return (
		<div>
			<Navbar menus={menus} config={config} />
			<Outlet />
			<Footer config={config} />
		</div>
	)
}

export default MainLayout
