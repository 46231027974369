import React from 'react'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { useLocation, useRoutes } from 'react-router-dom'
import { routes } from './routes'

function App() {
	const showRoutes = useRoutes(routes)

	const {pathname} = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname])

	return (
		<>
			{showRoutes}
			<Toaster position="bottom-left" reverseOrder={true} />
		</>
	)
}

export default App
