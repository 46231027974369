import { Navigate, useParams } from 'react-router-dom'
import HeroBackground from '../../components/hero'
import Details from './components/details'
import './blog.scss'
import Populars from './components/populars'
import { memo } from 'react'
import Seo from '../../components/meta'
import { resize } from '../../helper'
import Loader from '../../components/loader'
import { useGetProductQuery } from '../../store/services/api'

function Blog() {
	const { slug } = useParams()

	const { data: product, isLoading, error } = useGetProductQuery(slug)

	if (error) return <Navigate to="/" replace />

	if (isLoading) return <Loader />

	return (
		<section>
			<Seo
				title={'Replik Yazılım | ' + product.title}
				description={product.meta_desc}
				image={resize(product.image, 500, 500)}
			/>
			<HeroBackground
				title="Ürün İçerik"
				breadcrumb={['Anasayfa', product.title.slice(0, 20) + ' ...']}
			/>
			<section id="blog">
				<div className="container">
					<Details blog={product} />
					<Populars />
				</div>
			</section>
		</section>
	)
}

export default memo(Blog)
