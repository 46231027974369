import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import './customers.scss'
import { resize } from '../../helper'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import useInView from '../../hooks/useInView'
import { useEffect } from 'react'
import { useLazyGetCustomersQuery } from '../../store/services/api'

function Customers() {

	const { ref, inView } = useInView()

	const [trigger, { data: customers = [], isLoading }] = useLazyGetCustomersQuery()

	useEffect(() => {
		if (inView) {
			trigger()
		}
	}, [inView])

	return (
		<section ref={ref} id="customers">
			<div className="container">
				<h2>Müşterilerimiz</h2>
				<h1>Güvenilir Müşterilerimiz</h1>
				<div className="customer-slides">
					<Swiper
						loop={true}
						spaceBetween={30}
						autoplay={{
							delay: 1500,
							disableOnInteraction: false,
							stopOnLastSlide: false,
						}}
						breakpoints={{
							1200: {
								slidesPerView: 5,
							},
							992: {
								slidesPerView: 4,
							},
							0: {
								slidesPerView: 3,
							},
						}}
						allowTouchMove={false}
						slidesPerView={5}
						modules={[Autoplay]}
					>
						{customers.map((customer) => (
							<SwiperSlide key={customer.id}>
								<div className="customer-image">
									<LazyLoadImage
										src={resize(customer.image, 350, 200)}
										effect="blur"
									/>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</section>
	)
}

export default Customers
