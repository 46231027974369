import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const baseApi = createApi({
	reducerPath: 'baseApi',
	baseQuery: fetchBaseQuery({ baseUrl: 'https://biribenim.com/api/' }),
	endpoints: (builder) => ({
		getMenus: builder.query({
			query: () => 'navbar/',
		}),
		getContents: builder.query({
			query: () => 'content/',
		}),
		sendMessage: builder.mutation({
			query: (body) => ({
				url: 'contact/',
                method: 'POST',
                body
			}),
		}),
        getProducts: builder.query({
            query: ({ limit, offset }) => `blog/?limit=${limit}&offset=${offset}`
        }),
        getProduct: builder.query({
            query: (slug) => `blog/${slug}/`
        }),
        getCustomers: builder.query({
            query: () => 'customers/'
        }),
        getContent: builder.query({
            query: (slug) => `content/${slug}/`
        }),
		getConfigs: builder.query({
			query: () => 'config/',
			transformResponse: (response) => {
				const data = response.reduce(
					(obj, cur) => ({
						...obj,
						[cur.key]: cur.image ? cur.image : cur.value,
					}),
					{}
				)

				return data
			},
		}),
	}),
})

export const {
	useGetMenusQuery,
	useGetConfigsQuery,
	useGetContentsQuery,
	useLazyGetContentsQuery,
    useSendMessageMutation,
    useGetProductsQuery,
    useLazyGetProductsQuery,
    useLazyGetCustomersQuery,
    useGetContentQuery,
    useGetProductQuery
} = baseApi
